import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import swal from 'sweetalert';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';


let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const styles = ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    media: {
        margin: 10,
        width: 180,
        height: 120
    },
    media2: {
        margin: 10,
        width: 360,
        height: 120
    },
    bottom: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    palette: {
        primary: { main: "#e91e63", contrastText: "#fff" },
        secondary: { main: "#fff", contrastText: "#fff" }
      }
})



class Feedback extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            callBack: false,
            name: '',
            emailMobile: '',
            age: '',
            category: ''
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        console.log(name)
        console.log(value)
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        console.log(this.state);
        const feedback = this.state;
        this.setState({
            callBack: false,
            name: '',
            emailMobile: '',
            feedback: '',
            category: '',
            age:''
        });
        axios.post(`https://crickinngdom-com092018.firebaseio.com/pre-reg.json`, { feedback })
            .then(res => {
                console.log(res);
                console.log(res.data);
                swal("Thank You!", "Thanks for registration, We will contact you once registration are open!", "success")
            })
    }




    render() {
        const classes = this.props.classes;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>

                    <CardMedia
                        className={classes.media}
                        image={require("../../assets/logo.png")}
                        title="CricKingdom"
                    />
                    <Typography spacing={2} component="h1" variant="h5">
                        Pre Registration
                    </Typography>
                    <Box fontStyle="italic">
                        <Typography spacing={2} variant="subtitle1">
                            Join the CricKingdom Academies that are going to be launched in October ...
                    </Typography>
                    </Box>
                    <form onSubmit={this.handleSubmit} className={classes.form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                <TextField
                                    autoComplete="name"
                                    name="name"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    value={this.state.name}
                                    autoFocus
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="emailMobile"
                                    label="Email/Mobile"
                                    name="emailMobile"
                                    value={this.state.emailMobile}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="age"
                                    label="Age"
                                    name="age"
                                    value={this.state.age}
                                    onChange={this.handleInputChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">City</FormLabel>
                                    <RadioGroup aria-label="category" name="category" value={this.state.category} onChange={this.handleInputChange} row>
                                        <FormControlLabel
                                            value="Pune"
                                            control={<Radio color="primary" />}
                                            label="Pune"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Mumbai"
                                            control={<Radio color="primary" />}
                                            label="Mumbai"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Hubli"
                                            control={<Radio color="primary" />}
                                            label="Hubli"
                                            labelPlacement="end"
                                        />
                                        <FormControlLabel
                                            value="Sangli"
                                            control={<Radio color="primary" />}
                                            label="Sangli"
                                            labelPlacement="end"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox name="callBack" onChange={this.handleInputChange} checked={this.state.callBack} color="primary" />}
                                    label="I wish to talk directly with someone from CricKingdom Team."
                                />
                            </Grid>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary">
                                Send
                        </Button>
                        </Grid>
                    </form>

                    <Grid item xs={12} className={classes.bottom} >

                        <Typography spacing={2} component="h1" variant="h5">
                            Registration Open Now !!
                    </Typography>
                    <Link href="https://crickingdom.com/registration" target="_blank" rel="noopener" variant="body2">
                        <CardMedia
                            className={classes.media2}
                            image={require("../../assets/chennai.png")}
                            title="Chennai">
                            <Typography color="secondary" component="h1" variant="h5">
                                Chennai
                            </Typography>
                        </CardMedia>
                        </Link>
                        <Link href="https://www.crickingdom.sg/academy" target="_blank" rel="noopener" variant="body2">
                        <CardMedia
                            className={classes.media2}
                            image={require("../../assets/singapore.jpg")}
                            title="Singapore">
                            <Typography color="secondary" component="h1" variant="h5">
                                Singapore
                            </Typography>
                        </CardMedia>
                        </Link>
                    </Grid>
                </div>
            </Container>
        );
    }
}

export default withStyles(styles)(Feedback);